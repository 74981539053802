.single-comment {
  &.completed-comment {
    * {
      color: #008800;
    }
  }
  &.excluded-comment {
    * {
      color: #880000;
    }
  }
}
