.fast-preview {
  .metadata {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border: 2px solid rgba(43, 45, 55, 0.1);
    border-left: 0;
    border-right: 0;
    padding: 0 0 7px;
    width: 100%;
  }

  .metadata-item {
    display: inline-block;
    white-space: normal;
    margin-right: 30px;
  }

  .metadata-label {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .metadata-value {
    margin-bottom: 10px;
  }

  .result-comments {
    border: 1px solid rgba(43, 45, 55, 0.1);
    border-radius: 4px;
    padding: 15px 20px;

    .result-comments-submit {
      margin: 10px 0 0;
    }
  }

  .result-table {
    margin: 15px 0;
    overflow: hidden;
    overflow-x: auto;
  }

  .result-manual-data {
    margin: 15px 0;
  }

  .table-main {
    .MuiTableCell-head {
      min-width: 90px;
    }
  }

  .upload-box-container {
    margin-top: 15px;
  }
}

.modal-title {
  border: 2px solid rgba(43, 45, 55, 0.1);
  border-left: 0;
  border-right: 0;
  border-bottom: 2px;
  border-top: 0;
  padding: 0 0 20px 0;
}

.test-info {
  padding: 20px 0;
}
