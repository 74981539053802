.file-row {
  border-top: 1px solid rgba(43, 45, 55, 0.1);
  padding: 20px 0;
  &:last-of-type {
    border-bottom: 1px solid rgba(43, 45, 55, 0.1);
  }
}
.upload-button-list {
  margin-bottom: 10px;
  button {
    margin-left: 10px;
  }
}
.action-holder,
.file-browse {
  margin-top: 20px;
}

.upload-box {
  border: 1px solid rgba(43, 45, 55, 0.1);
  border-radius: 4px;
  margin-top: 30px;
  padding: 20px;
}

.upload-title {
  margin-bottom: 10px;
}

.upload-progress {
  margin: 20px 0;
}

.drag-active {
  opacity: 0.5;
  border: 1px dashed #000;
  position: relative;
}
.drag-active:after {
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  content: '+';
  color: #fff;
  background: green;
  border-radius: 50%;
}

.preview-link {
  cursor: pointer;
}
