.folder-view-accordion-title {
  font-size: 14px;
  line-height: 17px;
}
.MuiAccordion-root.folder-view-accordion, .folder-view-accordion {
  padding: 0 16px;
  margin: 0;
}
.folder-view-accordion .MuiAccordionDetails-root {
  padding: 0 16px;
}
.folder-view-accordion {
  flex-direction: row-reverse;
}
.folder-view-accordion.Mui-expanded {
  border-bottom: 1px solid #F4F6F8;
}
.folder-view-accordion-title svg {
  margin-right: 15px
}