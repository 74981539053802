.project-data-page {
  margin-top: 30px;
  padding: 20px;

  &__file-upload {
    margin-top: 20px !important;
  }
}

.folder-structure-holder {
  background: #fff;
  padding: 0 16px;
}

.document-comments {
  border: 1px solid rgba(43, 45, 55, 0.1);
  border-radius: 4px;
  padding: 15px 20px;
  margin-top: 50px !important;

  .document-comments-submit {
    margin: 10px 0 0;
  }
}

.result-comment-modal-comments {
  max-height: 350px;
  overflow: auto;

  & > .single-comment {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
}

.dialog-title {
  border-bottom: none !important;
  padding: 0px 16px !important;
}

.dialog-content {
  overflow-y: hidden !important;
  padding: 15px 15px !important;
}
